import {
  CloseCircleFilled,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  List,
  Divider,
  message,
  Checkbox,
} from "antd";
import { Modal, notification } from 'antd';

import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import get from "lodash/get";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getHospitalAds,
  postHospitalAds,
  deleteHospitalAds,
  updateHospitalAds,
  fetchHospitalAdById,
} from "../../redux/actions/hospitalAction";
import { DELETED_ADS } from "../../constants/messages";
import SingleImageUpload from "../../components/mediaUpload/SingleImageUpload";
import SingleVideoUpload from "../../components/mediaUpload/SingleVideoUpload";
import { useForm } from "antd/lib/form/Form";

const AdsScreen = ({ hospital }) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editAd, setEditAd] = useState(null);
  const [imageUser, setUserImage] = useState();
  const [videoUser, setUserVideo] = useState();
  const [form] = useForm();
  const [ads, setAds] = useState([]);

  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const createdBy = useSelector((state) => state?.auth?.id);

  const onSave = () => {
    setEdit(false);
  };

  const onEdit = (itemUuid, isEditing = false) => {
    setEdit(true);
    if (isEditing) {
      fetchHospitalAdById(itemUuid).then((res) => {
        const result = {
          ...res,
          start_date: moment(res?.start_date, "DD-MM-YYYY"),
          end_date: moment(res?.end_date, "DD-MM-YYYY"),
        };
        form.setFieldsValue(result);
        setEditAd(result);
        setUserImage(res?.image);
        setUserVideo(res?.video);
      });
    } else {
      form.resetFields();
      setEditAd(null);
      setUserImage(null);
      setUserVideo(null);
    }
  };

  const onClickBack = () => {
    setEdit(false);
    form.resetFields();
    setEditAd(null);
    setUserImage(null);
    setUserVideo(null);
  };

  const fetchAds = () => {
    setLoading(true);
    getHospitalAds(hospital_uuid)
      .then((result) => {
        setLoading(false);
        setAds(result);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetchAds();
  }, []);

  return (
    <Spin spinning={loading}>
      {edit ? (
        <EditAds
          onSave={onSave}
          setLoading={setLoading}
          ads={ads}
          editAd={editAd}
          fetchAds={fetchAds}
          hospital={hospital}
          loading={loading}
          createdBy={createdBy}
          onClickBack={onClickBack}
          imageUser={imageUser}
          setUserImage={setUserImage}
          videoUser={videoUser}
          setUserVideo={setUserVideo}
          form={form}
        />
      ) : (
        <ViewAds
          onEdit={onEdit}
          setLoading={setLoading}
          ads={ads}
          loading={loading}
          fetchAds={fetchAds}
        />
      )}
    </Spin>
  );
};

export default AdsScreen;

const EditAds = ({
  setLoading,
  loading,
  onSave,
  ads,
  fetchAds,
  hospital,
  createdBy,
  editAd,
  onClickBack,
  imageUser,
  setUserImage,
  videoUser,
  setUserVideo,
  form,
}) => {
  const onFinish = (values) => {
    const data = {
      isSponsor: componentDisabled,
      start_date: values?.start_date?.format("DD-MM-YYYY"),
      end_date: values?.end_date?.format("DD-MM-YYYY"),
      image: imageUser?.key || values?.image,
      text: editAd ? editAd?.text : values?.text,
      video: videoUser?.key || values?.video,
      createdBy,
    };

    const startDate = new Date(
      values?.start_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    const endDate = new Date(
      values?.end_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );

    if (!values?.start_date) {
      message.error("Please Select Start Date");
      return;
    }

    if (startDate > endDate) {
      message.error("End Date is earlier than Start Date");
      return;
    }

    setLoading(true);
    postHospitalAds(data)
      .then(() => {
        setLoading(false);
        onSave();
        fetchAds();
      })
      .catch(() => setLoading(false));
  };

  const onUpdateAds = (values) => {
    const data = {
      isSponsor: componentDisabled,
      start_date: values?.start_date?.format("DD-MM-YYYY"),
      end_date: values?.end_date?.format("DD-MM-YYYY"),
      image: imageUser?.key,
      text: values?.text ? values?.text : editAd?.text ,
      video: videoUser?.key,
      createdBy,
    };

    const startDate = new Date(
      values?.start_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    const endDate = new Date(
      values?.end_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );

    if (!values?.start_date || !values?.end_date) {
      message.error("Please Select Start and End Dates");
      return;
    }

    if (startDate > endDate) {
      message.error("End Date is earlier than Start Date");
      return;
    }

    setLoading(true);
    updateHospitalAds(editAd?.uuid, data)
      .then(() => {
        setLoading(false);
        onSave();
        fetchAds();
      })
      .catch(() => setLoading(false));
  };

  const dateFormat = "DD-MM-YYYY";
  const [componentDisabled, setComponentDisabled] = useState(editAd?.isSponsor);

  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
  };

  const onUploadVideoDone = (videoResponse) => {
    setUserVideo(videoResponse);
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      className="faqs_form"
      layout="vertical"
      onFinish={editAd?.id ? onUpdateAds : onFinish}
      autoComplete="off"
      form={form}
    >
      <Row
        style={{ display: "flex", marginBottom: 8 }}
        align="baseline"
        justify="space-between"
      >
        <Col span={22}>
          <Form.Item
            name="text"
            label="Text"
            rules={[{ required: true, message: "Please enter the text!" }]}
          >
            <Input placeholder="Text" className="custom-input" />
          </Form.Item>
          <Form.Item name="image" label="Image">
            <SingleImageUpload
              className="user-image"
              onUploadDone={onUploadLogoDone}
              imageUrl={get(imageUser, "url")}
              setUserImage={setUserImage}
            />
          </Form.Item>
          <Form.Item name="video" label="Video">
            <SingleVideoUpload
              className="user-image"
              onUploadDone={onUploadVideoDone}
              videoUrl={get(videoUser, "url")}
              setUserVideo={setUserVideo}
            />
            
          </Form.Item>
          <Form.Item name="isSponsor" label="Sponsor">
            <Checkbox
              checked={componentDisabled}
              onChange={(e) => setComponentDisabled(e.target.checked)}
            />
          </Form.Item>
          <Form.Item name="start_date" label="Start Date">
            <DatePicker format={dateFormat} />
          </Form.Item>
          <Form.Item name="end_date" label="End Date">
            <DatePicker format={dateFormat} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }}>
          {editAd?.id ? "Update" : "Submit"}
        </Button>
        <Button type="primary" onClick={onClickBack}>
          Back
        </Button>
      </Form.Item>
    </Form>
  );
};

const ViewAds = ({ onEdit, ads, loading, setLoading, fetchAds }) => {
  const onClickEdit = (itemUuid) => {
    onEdit(itemUuid, true);
  };


const onClickDelete = (itemUuid) => {
  Modal.confirm({
    title: 'Are you sure you want to delete this ad?',
    content: 'This action cannot be undone.',
    okText: 'Yes, delete it',
    okType: 'danger',
    cancelText: 'Cancel',
    onOk: () => {
      setLoading(true);
      deleteHospitalAds(itemUuid)
        .then(() => {
          setLoading(false);
          notification.success({ message: DELETED_ADS });
          fetchAds();
        })
        .catch(() => setLoading(false));
    },
  });
};


  return (
    <Spin spinning={loading}>
      {ads && (
        <>
          <h3>Hospital Ads</h3>
          <List
            itemLayout="horizontal"
            dataSource={ads}
            renderItem={(item) => (
              <>
                <List.Item>
                  <List.Item.Meta title="" description={item.text} />
                  <Button
                    type="primary"
                    onClick={() => onClickEdit(item?.uuid)}
                    style={{ marginRight: "8px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => onClickDelete(item?.uuid)}
                    icon={<DeleteOutlined />}
                  ></Button>
                </List.Item>
              </>
            )}
          />
          <Button type="primary" onClick={() => onEdit(null, false)}>
            Create Ads
          </Button>
        </>
      )}
    </Spin>
  );
};
