export const SUPER_ADMIN_ROLE = 1;
export const ADMIN_ROLE = 2;
export const DOCTOR_ROLE = 3;
export const MANAGER_ROLE = 4;
export const DELEGATE_ROLE = 5;
export const PATIENT_ROLE = 6;

export const APPOINTMENT_STATUS_OPTIONS = {
    incomplete: { name: 'In complete', value: 0 },
    // pending: { name: 'Pending', value: 1 },
    noShow: { name: 'No Show', value: 1 },
    done: { name: 'Done', value: 3 },
    cancelled: { name: 'Cancelled', value: 4 },
    checkedIn: { name: 'CheckedIn', value: 5 },
    // accepted: { name: 'Accepted', value: 6 }
  };