import React, { useState, useEffect } from "react";
import { Select, Form, Button, Radio, Modal } from "antd";
import EmergencyFormFamily from "./EmergencyFormFamily";
import OutsideForm from "./OutsideForm";

const SelectionTypeForm = ({
  membersData,
  doctor_uuid,
  hospital_uuid,
  closeModal,
  currentSlot,
  slotType,
  getAppointments,
  filter,
  selectedFilteredUser,
  setSelectedFilteredUser,
  consultationType,
  setConsultationType
}) => {
  const [form] = Form.useForm();
  const [openFormModal, setOpenFormModal] = useState(false);
  const [filteredUser, setFilteredUser] = useState(null);
  const onChangeUser = (value) => {
    const filterMemberData = membersData?.filter(
      (member) => member?.id === value
    );
    setFilteredUser(filteredUser);
    setSelectedFilteredUser(filterMemberData?.[0]);
  };

  const onFinish = (values) => {
    setConsultationType(values?.consultation_type);
    form.resetFields(); // Reset form fields after submit
    setOpenFormModal(true);
    closeOutSideModal();
  };

  const closeOutSideModal = () =>{
    form.resetFields(); 
    closeModal();
  }

  const closeFormModal = () => {
    setOpenFormModal(false);
    setConsultationType(null); // Clear consultation type
    setSelectedFilteredUser(null); // Reset filtered user
    form.resetFields(); // Reset form fields when modal is closed
    closeModal();
  };

  // Ensure form reset whenever modal is closed
  useEffect(() => {
    if (!selectedFilteredUser && !consultationType) {
      form.resetFields();
    }
  }, [selectedFilteredUser, consultationType]);

  return (
    <>
      <Form form={form} onFinish={onFinish} initialValues={{user:null,consultation_type:null}}>
        <Form.Item
          name="user"
          rules={[
            {
              required: true,
              message: "Please select User",
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            onChange={onChangeUser}
            placeholder="Select User"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            value={filteredUser}
            options={membersData
              ?.filter(
                (member) => member?.name != null && member?.email !== null
              )
              ?.map((member) => ({
                label: member?.name,
                value: member?.id,
              }))}
          />
        </Form.Item>
        <Form.Item
          name="consultation_type"
          rules={[
            {
              required: true,
              message: "Please select Relation",
            },
          ]}
        >
          <Radio.Group>
            <div className="select-area">
              <Radio value={1}>Family</Radio>
              <Radio value={2}>Outside</Radio>
            </div>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Modal
        onCancel={closeFormModal}
        open={openFormModal}
        footer={null}
      >
        {consultationType === 1 && (
          <EmergencyFormFamily
            doctor_uuid={doctor_uuid}
            hospital_uuid={hospital_uuid}
            filteredUser={selectedFilteredUser}
            closeFormModal={closeFormModal}
            currentSlot={currentSlot}
            slotType={slotType}
            getAppointments={getAppointments}
            filter={filter}
          />
        )}
        {consultationType === 2 && (
          <OutsideForm
            doctor_uuid={doctor_uuid}
            filteredUser={selectedFilteredUser}
            hospital_uuid={hospital_uuid}
            // filteredUser={selectedFilteredUser?.user}
            closeFormModal={closeFormModal}
            currentSlot={currentSlot}
            slotType={slotType}
            openFormModal={openFormModal} 
            getAppointments={getAppointments}
            filter={filter}
          />
        )}
      </Modal>
    </>
  );
};

export default SelectionTypeForm;
