import { useState } from 'react';
import { Upload, Spin, message } from 'antd';
import get from 'lodash/get';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CONTACTUS_VIDEO_UPLOAD } from '../../constants/apiEndpoints';

const SingleVideoUpload = (props) => {
  const { videoUrl, onUploadDone, setUserVideo } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // State to store error messages

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = (info) => {
    if (get(info, 'file.status') === 'uploading') {
      setLoading(true);
      return;
    }
    if (get(info, 'file.status') === 'done') {
      setLoading(false);
      onUploadDone(get(info, 'file.response.data'));
      setError(null); // Clear error on successful upload
    } else {
      setLoading(false);
    }
  };

  const handleRemove = () => {
    setUserVideo(null);
  };

  const beforeUpload = (file) => {
    const isLt100MB = file.size / 1024 / 1024 < 100; // Check if file size is less than 100MB
    if (!isLt100MB) {
      setError('File size must be smaller than 100MB!');
      message.error('File size must be smaller than 100MB!'); // Display message to the user
    }
    return isLt100MB; // Prevent upload if file is too large
  };

  return (
    <Spin spinning={loading}>
      {error && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          {error}
        </div>
      )}
      {videoUrl ? (
        <div style={{ position: 'relative', width: 200, height: 200 }}>
          <video src={videoUrl} alt="Preview" width={200} height={200} controls />
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer',
              background: '#fff',
              padding: '4px',
            }}
            onClick={handleRemove}
          >
            Remove
          </div>
        </div>
      ) : (
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          accept="video/*"
          action={CONTACTUS_VIDEO_UPLOAD}
          beforeUpload={beforeUpload} // Add size validation here
          onChange={handleChange}
        >
          {videoUrl ? (
            <video src={videoUrl} style={{ width: '100%' }} controls />
          ) : (
            uploadButton
          )}
        </Upload>
      )}
    </Spin>
  );
};

export default SingleVideoUpload;
