import map from "lodash/map";

export const putTimeHelper = (availability) => {
  const dayMap = new Map();

  map(availability, (singleTime) => {
    map(singleTime.days, (day) => {
      dayMap.set(day, {
        day: day,
        from_time: singleTime.from_time,
        to_time: singleTime.to_time,
      });
    });
  });
  const modified_availability = Array.from(dayMap.values());

  return modified_availability;
};
