export const DASHBOARD = '/dashboard';
export const APPOINTMENTS = '/appointments';
export const DOCTORS = '/doctors';
export const LINEUP = '/:id';
export const HOSPITAL_DETAILS = '/hospitaldetails';
export const DEPARTMENTS = '/departments';
export const HOSPITAL_ADMINS = '/hospitaladmins';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password/:uuid';
export const LOGIN = '/login';
export const CREATE_ACCOUNT = '/create_account';
export const AVAILABILITY = '/availability';
export const ASSISTANTS = '/assistants';
export const REGISTER_DOCTOR = '/register-doctor/:uuid';
export const REGISTER_HOSPITAL_AGENT = '/register-hospital-agent/:uuid';
export const REGISTER_STAFF = '/staff/:uuid';
export const HOSPITALS = '/hospitals';
export const SETTINGS = '/settings';
export const CITIES = '/cities';
export const BILLINGS = '/billings';
export const CONTACT_US = "/contactus";
export const COMMENTS = '/comments';
export const DOCTOR_QUESTIONS = '/doctor-questions';
export const ADS = "/ads";
export const TV_ADS = "/tv-ads";
export const MEDIA_AND_ADS = "/media-ads";
export const CURRENT_TV_ADS = "/media-ads/:currentTvId";
export const CURRENT_DATE_TV_ADS = "/tv-ads/:currentTvId";
export const NOTIFICATIONS = "/notifications";
export const QUESTIONS = "/questions";
export const HOSPITAL_AGENTS = "/hospital-agents";
export const HOSPITAL_PATIENTS = "/hospital-patients";
export const getLeftPanelKey = (currentPath) => {
  switch (currentPath) {
    case DASHBOARD:
      return '0';
    case APPOINTMENTS:
      return '1';
    case HOSPITALS:
      return '2';
    case ASSISTANTS:
      return '3';
    case AVAILABILITY:
      return '4';
    case DOCTORS:
      return '5';
    case HOSPITAL_DETAILS:
      return '6';
    case DEPARTMENTS:
      return '7';
    case HOSPITAL_ADMINS:
      return '8';
    case SETTINGS:
      return '9';
    case CITIES:
      return '10';
    case BILLINGS:
      return '11';
    case CONTACT_US :
      return "12";
    case ADS : 
      return "13";
    case NOTIFICATIONS : 
      return "14";
    case QUESTIONS :
      return "15"
    case COMMENTS :
        return "16";
    case HOSPITAL_AGENTS :
        return "17"
    case DOCTOR_QUESTIONS:
      return '18';
      // case TV_ADS : 
      // return "19";
      case MEDIA_AND_ADS : 
      return "19";
      case CURRENT_TV_ADS:
        return "22";
      case CURRENT_DATE_TV_ADS :
        return "21";
      case HOSPITAL_PATIENTS :
        return "20";

    default:
      return '0';
  }
};
